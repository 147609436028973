(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = 'https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v7.0';
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));


// ページプラグインの埋め込みコードを返す。
function pagePluginCode(w) {
  // console.log("hoge2");
  // 幅に応じて高さを変更する場合
  if(w > 400) {
    var h = 280;
  } else {
    var h = 200;
  }
  return `<div class="fb-page" data-href="https://www.facebook.com/DX.With/" data-tabs="timeline" data-width="${w}" data-height="${h}" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/DX.With/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/DX.With/">DX.With</a></blockquote></div>`;
}



// ページプラグインを追加する要素
var facebookWrap = $('.js-facebookTimeline');
var fbBeforeWidth = ''; // 前回変更したときの幅
var fbWidth = facebookWrap.width(); // 今回変更する幅
var fbTimer = false;
$(window).on('load resize', function() {
  // console.log("hoge");
  if (fbTimer !== false) {
    clearTimeout(fbTimer);
  }
  fbTimer = setTimeout(function() {
    fbWidth = facebookWrap.width(); // 変更後の幅を取得
    // 前回の幅から変更があった場合のみ処理
    // スマホだとスクロール時にリサイズが発生することがあるため
    if(fbWidth != fbBeforeWidth) {
      facebookWrap.html(pagePluginCode(fbWidth)); // ページプラグインのコード変更
      window.FB.XFBML.parse(); // ページプラグインの再読み込み
      fbBeforeWidth = fbWidth; // 今回変更分を保存しておく
    }
  }, 200);
});
// $(function() {
// });
